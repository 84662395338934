import { Box } from '@chakra-ui/layout'

import { DrawerHeader } from '@/components/ui'
import { IncidentQueryResult } from '@/graphql/generated/hooks'
import { IncidentStatus } from '@/graphql/generated/schemas'

import { MixpanelDataIProps } from '../../types/types'
import { ResolveForm } from './ResolveForm'

export type IncidentDataIProps = IncidentQueryResult['data']['incident']
export interface ResolveIProps {
  incident?: IncidentDataIProps
  mixpanelData?: MixpanelDataIProps
  onActionComplete?: () => void
}

export const Resolve = ({
  incident,
  mixpanelData,
  onActionComplete,
}: ResolveIProps) => {
  const status = incident?.status

  if (!Object.values(IncidentStatus).includes(status)) {
    return null
  } else {
    return (
      <Box>
        <DrawerHeader header='Resolve' />
        <ResolveForm
          incident={incident}
          mixpanelData={mixpanelData}
          onActionComplete={onActionComplete}
        />
      </Box>
    )
  }
}
