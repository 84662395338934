import { useEffect, useState } from 'react'

import { Box } from '@chakra-ui/react'
import { useInViewRef } from 'rooks'

import { DeviceFeed } from '@/features/device-feed'
import {
  VideoFeedEvent,
  VideoPlayer,
  useFindDeviceFeed,
  useIncidentVideo,
} from '@/features/video-player'
import { IncidentType } from '@/graphql/generated/schemas'
import { AnalyticsScreen, PlayerEvents, useMixpanel } from '@/utils/analytics'

import { MixpanelDataIProps } from '../types/types'
import { ShowVideoButton } from './ShowVideoButton'

export interface IncidentVideoIProps {
  incidentId: string
  deviceId: string
  incidentType: IncidentType
  isLoading: boolean
  scrollToTop: () => void
  mixpanelData: MixpanelDataIProps
}

export const IncidentVideo = ({
  incidentId,
  deviceId,
  incidentType,
  isLoading,
  scrollToTop,
  mixpanelData,
}: IncidentVideoIProps) => {
  const [isPipActive, setPIPActive] = useState(false)
  const [playerUrl, setPlayerUrl] = useState(null)
  const [isLiveStreamEnabled, setLiveStreamEnabled] = useState(false)
  const [videoRef, isInView] = useInViewRef()

  const {
    trackEvent,
    createPlayerActionHandler,
    createIncidentVideoEventHandler,
    createCameraEventHandler,
  } = useMixpanel(AnalyticsScreen.ActiveIncidentDrawer)

  const handlePlayerAction = createPlayerActionHandler(mixpanelData)
  const handleIncidentVideoEvent = createIncidentVideoEventHandler(mixpanelData)
  const handleCameraEvent = createCameraEventHandler(mixpanelData)

  const {
    availableCamerasIds,
    isDeviceDataLoading,
    errorMessage: feedError,
    hasCamera,
    nextCamera,
    previousCamera,
    stream: { url: cameraFeedUrl, isLoading: isFeedDataLoading },
  } = useFindDeviceFeed(deviceId, handleCameraEvent)

  const {
    url: videoUrl,
    isLoading: isVideoUrlLoading,
    errorMessage: incidentError,
    isProcessing,
  } = useIncidentVideo({
    hasCamera: hasCamera,
    incidentId: incidentId,
    incidentType: incidentType,
    onEvent: handleIncidentVideoEvent,
    skip: isDeviceDataLoading,
  })

  const isVideoLoading =
    isLoading ||
    (isLiveStreamEnabled && isFeedDataLoading) ||
    (!isLiveStreamEnabled && isVideoUrlLoading)

  const disablePIP = () => setPIPActive(false)
  const enablePIP = () => setPIPActive(true)

  useEffect(() => {
    setPlayerUrl(isLiveStreamEnabled ? cameraFeedUrl : videoUrl)
  }, [videoUrl, cameraFeedUrl, isLiveStreamEnabled])

  useEffect(() => {
    if (isLiveStreamEnabled) {
      trackEvent(PlayerEvents.toggleLiveFeed, mixpanelData)
    } else {
      trackEvent(PlayerEvents.toggleIncidentClip, mixpanelData)
    }
  }, [isLiveStreamEnabled])

  const handleStartPlaying = () => {
    if (isLiveStreamEnabled) {
      handleCameraEvent(VideoFeedEvent.Playing)
    } else {
      handleIncidentVideoEvent(VideoFeedEvent.Playing)
    }
  }

  return (
    <>
      <Box
        bg='white'
        data-testid='incidentsPage_drawer_incidentVideo'
        position='sticky'
        pt='20px'
        ref={videoRef}
        top='-352px'
        zIndex='sticky'
      >
        <Box px='24px' w='100%'>
          <DeviceFeed
            isLoading={isVideoLoading}
            onClickNext={nextCamera}
            onClickPrevious={previousCamera}
            showCameraSwitcher={availableCamerasIds.length > 1}
          >
            <VideoPlayer
              autoplay={true}
              disablePIP={disablePIP}
              errorLabel={isLiveStreamEnabled ? feedError : incidentError}
              isProcessing={isProcessing}
              liveEnabled={isLiveStreamEnabled}
              onPlayerControlAction={handlePlayerAction}
              onStartPlaying={handleStartPlaying}
              onToggleLive={setLiveStreamEnabled}
              pipActive={isPipActive}
              placeholderHeight='324px'
              placeholderWidth='577px'
              url={playerUrl}
            />
          </DeviceFeed>
        </Box>
      </Box>
      <Box bg='white' boxShadow='lg' position='sticky' top='0' zIndex='sticky'>
        <ShowVideoButton
          disablePIP={disablePIP}
          enablePIP={enablePIP}
          onClick={scrollToTop}
          pipActive={isPipActive}
          show={playerUrl && !isInView}
          url={playerUrl}
        />
      </Box>
    </>
  )
}
