import { Box } from '@chakra-ui/layout'

import { DrawerHeader } from '@/components/ui'
import { DeviceType, IncidentStatus } from '@/graphql/generated/schemas'

import { MixpanelDataIProps } from '../../types/types'
import { OrderRepairForm } from './OrderRepairForm'

export interface IProps {
  facilityId: string
  deviceType: DeviceType
  incidentId: string
  incidentStatus: IncidentStatus
  mixpanelData?: MixpanelDataIProps
  onActionComplete?: () => void
}

export const OrderRepair = ({
  facilityId,
  deviceType,
  incidentId,
  incidentStatus,
  mixpanelData,
  onActionComplete,
}: IProps) => {
  return (
    <Box>
      <DrawerHeader
        header='Order Repair'
        subHeader='Select a Technician and enter an Order Message to send a Repair Order'
      />
      <Box>
        <OrderRepairForm
          deviceType={deviceType}
          facilityId={facilityId}
          incidentId={incidentId}
          incidentStatus={incidentStatus}
          mixpanelData={mixpanelData}
          onActionComplete={onActionComplete}
        />
      </Box>
    </Box>
  )
}
