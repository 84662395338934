import { FunctionComponent } from 'react'

import { chakra, shouldForwardProp } from '@chakra-ui/react'
import { AnimatePresence, isValidMotionProp, motion } from 'framer-motion'

import { INCIDENT_DRAWER_WIDTH, SOP_DRAWER_WIDTH } from './SOPIncidentDrawer'

/**
 * Allow motion props and non-Chakra props to be forwarded.
 */
const MotionBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
})

export const SOPIncidentDrawerContainer: FunctionComponent = ({ children }) => {
  return (
    <AnimatePresence>
      <MotionBox
        animate={{ right: INCIDENT_DRAWER_WIDTH }}
        backgroundColor='white'
        boxShadow='inset -6px 0 15px -6px rgba(0,0,0,0.15)'
        data-testid='SOPIncidentDrawerContainer:wrapper'
        exit={{ right: 0 }}
        h='100vh'
        initial={{ right: 0 }}
        position='absolute'
        top='0'
        // Reference: https://chakra-ui.com/getting-started/with-framer
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        transition={{ duration: 0.1, ease: 'easeInOut' }}
        w={SOP_DRAWER_WIDTH}
        zIndex={-1}
      >
        {children}
      </MotionBox>
    </AnimatePresence>
  )
}
