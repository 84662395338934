import { useRef } from 'react'

import { CgMail, CgRowFirst, CgToday } from 'react-icons/cg'
import { useSelector } from 'react-redux'
import { useHover } from 'usehooks-ts'

import {
  Box,
  Grid,
  Icon,
  Popover,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Square,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import styled from '@emotion/styled'

import { Help } from '@/components/icons'
import { selectMe } from '@/redux/me/meSlice'
import { selectSideNavOpen } from '@/redux/ui/uiSlice'
import { BUTTON_PRESS, mixpanel } from '@/utils/analytics'

const StyledSideBarHelp = styled.div<{ $show: boolean }>`
  cursor: pointer;
  display: grid;
  grid-template-columns: max-content max-content;
  gap: 5px;
  align-items: center;
  width: ${(p) => (p.$show ? '240px' : '60px')};
  transition: 0.3s;

  &:hover {
    background-color: #1b253f;

    .chakra-icon {
      color: #fff;
    }
  }
`
const LinkItem = ({
  href,
  icon,
  title,
  subTitle,
}: {
  href?: string
  icon?: React.ElementType
  title?: string
  subTitle?: string
}) => {
  const hoverRef = useRef(null)
  const isHover = useHover(hoverRef)
  return (
    <a href={href} rel='noopener noreferrer' target='_blank'>
      <Grid
        _hover={{
          bgColor: '#F8FAFF',
        }}
        cursor='pointer'
        gap='10px'
        p='16px'
        ref={hoverRef}
        rounded='16px'
        templateColumns='40px 1fr'
        transition='ease-in-out'
        transitionDuration='300ms'
        transitionProperty='all'
      >
        <Icon
          as={icon}
          boxSize='26px'
          color={isHover ? '#0059FF' : '#5A6C9A'}
        />
        <Box>
          <Box
            color='#091848'
            fontSize='16px'
            fontWeight='medium'
            lineHeight='1'
            mb='5px'
          >
            {title}
          </Box>
          <Box color='#5A6C9A' fontSize='12px' fontWeight='light'>
            {subTitle}
          </Box>
        </Box>
      </Grid>
    </a>
  )
}

export const SideBarHelp = () => {
  const me = useSelector(selectMe)
  const isSideNavOpen = useSelector(selectSideNavOpen)
  return (
    <Popover
      onClose={() => mixpanel.track('Closed Help & Support Popover')}
      onOpen={() =>
        mixpanel.track(`${BUTTON_PRESS} Opened Help & Support Popover`)
      }
      placement='right'
    >
      {({ isOpen }) => (
        <>
          <PopoverTrigger>
            <StyledSideBarHelp
              $show={isSideNavOpen}
              data-testid='sidebar_navItem_help'
            >
              <Tooltip
                bgColor='#1b253f'
                color='#fff'
                fontSize='16px'
                fontWeight='semibold'
                isDisabled={isSideNavOpen || isOpen}
                label='Help'
                letterSpacing='-0.65px'
                p='2'
                placement='right'
                rounded='5px'
              >
                <Square cursor='pointer' size='60px'>
                  <Icon
                    as={Help}
                    boxSize='24px'
                    strokeColor={isOpen ? '#fff' : '#5a6c9a'}
                  />
                </Square>
              </Tooltip>
              {isSideNavOpen && (
                <Box
                  color='#fff'
                  fontSize='16px'
                  fontWeight='medium'
                  letterSpacing='-0.65px'
                >
                  Help
                </Box>
              )}
            </StyledSideBarHelp>
          </PopoverTrigger>
          <Portal>
            <PopoverContent
              data-testid='SideBarHelp:popover'
              mb='2'
              py='60px'
              rounded='16px'
              w='400px'
            >
              <Box px='50px' w='full'>
                <Box
                  color='#091848'
                  fontSize='36px'
                  mb='5px'
                  w='auto'
                >{`Hi ${me?.firstName}!`}</Box>

                <Box
                  borderBottom='1px solid #DFDFDF'
                  color='#091848'
                  fontSize='12px'
                  fontWeight='light'
                  pb='15px'
                  w='200px'
                >
                  You’ve come to right place lets see how we can help you
                </Box>
                <Box
                  color='#5A6C9A'
                  fontSize='12px'
                  fontWeight='light'
                  pt='15px'
                  w='200px'
                >
                  Help & Support
                </Box>
              </Box>

              <PopoverCloseButton
                _hover={{
                  color: '#091848',
                  borderColor: '#091848',
                }}
                border='2px solid #C9CDDA'
                color='#C9CDDA'
                right='22px'
                rounded='full'
                top='22px'
              />
              <VStack align='stretch' pt='10px' px='30px'>
                <LinkItem
                  href='https://hivewatch.zendesk.com/hc/en-us'
                  icon={CgToday}
                  subTitle='Read all out product overviews and FAQs!'
                  title='Knowledge Base'
                />
                <LinkItem
                  href='mailto:support@hivewatch.zendesk.com'
                  icon={CgMail}
                  subTitle='Any questions you have, we got you'
                  title='Email Us'
                />
                <LinkItem
                  href='https://hivewatch.zendesk.com/hc/en-us'
                  icon={CgRowFirst}
                  subTitle='Get answers faster!'
                  title='Zendesk Chat'
                />
              </VStack>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  )
}
