import React from 'react'

import { Flex, HStack, useDisclosure } from '@chakra-ui/react'

import { MediaThumbnail } from './MediaThumbnail'
import { PreviewMedia } from './PreviewMedia'

interface SelectedFilesPreviewIProps {
  previewFile: File
  removeFile: (file: File) => void
  selectedFiles: File[]
  setPreviewFile: (file: File) => void
}

export const SelectedFilesPreview = ({
  previewFile,
  removeFile,
  selectedFiles,
  setPreviewFile,
}: SelectedFilesPreviewIProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <HStack mt={3}>
      {selectedFiles.map((file: File) => (
        <Flex
          alignContent='center'
          cursor='pointer'
          flexDirection='column'
          key={file.name}
          onClick={() => {
            onOpen()
            setPreviewFile(file)
          }}
          position='relative'
        >
          <MediaThumbnail file={file} removeFile={removeFile} />
        </Flex>
      ))}
      {previewFile && (
        <PreviewMedia
          isOpen={isOpen}
          onClose={onClose}
          previewFile={previewFile}
        />
      )}
    </HStack>
  )
}
